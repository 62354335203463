// src
import * as styleVars from '@common/styles/constants'

import {
  AuthFormHeader,
  AuthFormSupportingText,
  AuthInnerFormWrapper,
  authFormSubmitBtnCss,
  checkboxCss,
} from '../styles'
import { Controller, FormProvider } from 'react-hook-form'

import Button from '@common/components/button/v2'
import { Form } from '@palqee/ui'
import Link from 'next/link'
import dayjs from 'dayjs'
import dynamic from 'next/dynamic'
import { useHubspotChat } from '@features/core/hubspot/chat/use-hubspot-chat'
import { useIntl } from '@palqee/intl'
import utc from 'dayjs/plugin/utc'
import Image from 'next/image'
import { AuthIdentityProviderCodeEnum } from '@generated/types.d'
import { useLogin } from '@features/auth/hooks/use-login'
import { SelectDataRegion } from './data-region'
import { LoginProps } from '@features/auth/types/login'
import { useState } from 'react'

const Spinner = dynamic(() => import('@common/components/spinner'), {
  ssr: false,
})

dayjs.extend(utc)

const LoginForm = (props: LoginProps) => {
  const intl = useIntl()

  // load live chat
  useHubspotChat()

  const {
    get: {
      loginLoading,
      companyName,
      companyLogo,
      showDataRegionAndError,
      dataRegionOptions,
    },
    methods,
    handlers: {
      onCheckCompanyIdentityApplication,
      onSubmitAwsCognitoApplicationForm,
      onSubmitSsoForm,
    },
  } = useLogin(props)

  const { control, handleSubmit, formState, watch, setValue } = methods

  const [disableLogin, setDisableLogin] = useState(false)
  const identityApplication = watch('identityApplication')

  const renderSsoButtonLabel = () => {
    return intl.formatMessage({
      id: 'auth.login.button.label.sign_in_with_sso',
      defaultMessage: 'Sign in with SSO',
    })
  }

  const renderFormSubmitButtonText = (): string | JSX.Element => {
    const renderInitialButtonText = () => {
      if (!loginLoading)
        return (
          <div>
            {intl.formatMessage({
              id: 'auth.login.button.label.next',
              defaultMessage: 'Next',
            })}
          </div>
        )

      return (
        <>
          <Spinner className="pq-mr-1" color={styleVars.white} />
        </>
      )
    }

    const renderAwsCognitoButtonText = () => {
      if (!loginLoading) {
        return (
          <div>
            {intl.formatMessage({
              id: 'LOGIN_BUTTON',
              defaultMessage: 'Log in',
            })}
          </div>
        )
      }

      return (
        <>
          <Spinner className="pq-mr-1" color={styleVars.white} />
          {intl.formatMessage({
            id: 'LOGGING_IN',
            defaultMessage: 'Logging In..',
          })}
        </>
      )
    }

    const renderSsoButtonText = () => {
      // SSO
      if (!loginLoading) {
        return <div>{renderSsoButtonLabel()}</div>
      }

      return (
        <>
          <Spinner className="pq-mr-1" color={styleVars.white} />
          {intl.formatMessage({
            id: 'auth.login.button.label.signing_in_with_sso',
            defaultMessage: 'Signing In..',
          })}
        </>
      )
    }

    if (!identityApplication || !identityApplication?.identityProviderCode) {
      return renderInitialButtonText()
    } else if (
      identityApplication?.identityProviderCode ===
      AuthIdentityProviderCodeEnum.MicrosoftEntra
    ) {
      return renderSsoButtonText()
    }

    return renderAwsCognitoButtonText()
  }

  const renderPasswordField = () => {
    if (
      !identityApplication?.identityProviderCode ||
      identityApplication?.identityProviderCode ===
        AuthIdentityProviderCodeEnum.MicrosoftEntra
    )
      return null

    return (
      <div className="pq-mt-1">
        <Controller
          render={({ field }) => {
            return (
              <Form.Input
                {...field}
                caption={intl.formatMessage({
                  id: 'PASSWORD',
                  defaultMessage: 'Password',
                })}
                type="password"
                errors={formState?.errors}
              />
            )
          }}
          name="password"
          control={control}
        />
      </div>
    )
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()

    let submitFunc = onCheckCompanyIdentityApplication

    if (
      identityApplication?.identityProviderCode ===
      AuthIdentityProviderCodeEnum.AwsCognito
    ) {
      submitFunc = onSubmitAwsCognitoApplicationForm
    } else if (
      identityApplication?.identityProviderCode ===
      AuthIdentityProviderCodeEnum.MicrosoftEntra
    ) {
      submitFunc = onSubmitSsoForm
    }

    await handleSubmit(submitFunc)(e)
  }

  const renderSupportLinks = () => {
    if (
      identityApplication?.identityProviderCode ===
      AuthIdentityProviderCodeEnum.MicrosoftEntra
    ) {
      return (
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <p data-cy="sign-in-email" className="pass-reset-link ">
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault()

                setValue('identityApplication', {
                  identityProviderCode: AuthIdentityProviderCodeEnum.AwsCognito,
                  uriInit: null,
                })
              }}
            >
              {intl.formatMessage({
                id: 'auth.login.link.sign_with_email',
                defaultMessage: 'Sign in with email',
              })}
            </Link>
          </p>
        </div>
      )
    }

    return (
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <p data-cy="forgot-password-link" className="pass-reset-link ">
          <Link id="forgot-password" href="/auth/forgot-password">
            {intl.formatMessage({
              id: 'FORGOT_PASSWORD',
              defaultMessage: 'Forgot password?',
            })}
          </Link>
        </p>
      </div>
    )
  }

  const renderBackButton = () => {
    if (!identityApplication?.identityProviderCode) return null

    return (
      <div
        onClick={() => {
          setValue('identityApplication', {
            identityProviderCode: null,
            uriInit: '',
          })
        }}
        style={{
          marginTop: '15px',
          cursor: 'pointer',
        }}
      >
        <p className="pq-text-center pq-font-weight-bold pq-text-color-primary-red">
          {intl.formatMessage({
            id: 'auth.login.button.label.go_back',
            defaultMessage: 'Go Back',
          })}
        </p>
      </div>
    )
  }

  return (
    <AuthInnerFormWrapper className="LoginForm">
      <AuthFormHeader data-cy="login">
        {companyName && (
          <div
            className="pq-mb-2"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {companyLogo && (
              <Image
                src={companyLogo}
                style={{ objectFit: 'contain', marginBottom: '10px' }}
                alt={companyName}
                sizes="33vw"
                width={120}
                height={104}
              />
            )}
            <h1>{companyName}</h1>
          </div>
        )}
        {intl.formatMessage({ id: 'LOGIN', defaultMessage: 'Login' })}
      </AuthFormHeader>
      <AuthFormSupportingText>
        {intl.formatMessage({
          id: 'LOGIN_FORM_TEXT',
          defaultMessage: 'Enter your credentials below',
        })}
      </AuthFormSupportingText>
      <div className="login-form-wrapper">
        <FormProvider {...methods}>
          <Form data-cy="form-login" onSubmit={onSubmitHandler}>
            {identityApplication?.identityProviderCode ===
              AuthIdentityProviderCodeEnum.AwsCognito &&
              showDataRegionAndError && (
                <SelectDataRegion
                  dataRegionOptions={dataRegionOptions}
                  setDisableLogin={setDisableLogin}
                />
              )}
            <div>
              <Controller
                render={({ field }) => {
                  return (
                    <Form.Input
                      {...field}
                      caption={intl.formatMessage({
                        id: 'EMAIL',
                        defaultMessage: 'Email',
                      })}
                      errors={formState?.errors}
                      type="email"
                    />
                  )
                }}
                name="email"
                control={control}
              />
            </div>
            {renderPasswordField()}

            <div className="pq-mt-1">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {identityApplication?.identityProviderCode ===
                  AuthIdentityProviderCodeEnum.AwsCognito && (
                  <Controller
                    render={({ field }) => {
                      return (
                        <Form.Checkbox
                          {...field}
                          caption={
                            <span css={checkboxCss}>
                              {intl.formatMessage({
                                id: 'REMEMBER_ME',
                                defaultMessage: 'Remember me',
                              })}
                            </span>
                          }
                        />
                      )
                    }}
                    name="rememberMe"
                    control={control}
                  />
                )}
                {renderSupportLinks()}
              </div>
            </div>

            <Button
              className="pq-mt-3"
              btnTheme="primary-red"
              id="login-btn"
              type="submit"
              wrapperCss={authFormSubmitBtnCss}
              css={authFormSubmitBtnCss}
              disabled={!formState.isValid || loginLoading || disableLogin}
            >
              {renderFormSubmitButtonText()}
            </Button>
            {renderBackButton()}
          </Form>
        </FormProvider>
      </div>
      {/* temporarily disable

      <AuthFormBelow>
        {intl.formatMessage(
          {
            id: 'login.no_account',
            defaultMessage: "Don't have an account {link}",
          },
          {
            link: (
              <Button
                className="pq-ml-1"
                id="signup-btn"
                onClick={() => {
                  router.push('/auth/sign-up/')
                }}
                btnTheme="link-primary-red"
              >
                {intl.formatMessage({
                  id: 'login.sign_up_free',
                  defaultMessage: "Sign up, it's free!",
                })}
              </Button>
            ),
          },
        )}
      </AuthFormBelow>*/}
    </AuthInnerFormWrapper>
  )
}

export default LoginForm
